body {
  background: #333;
  /* padding-top: 30px; */
  /* justify-content: center; */
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
}

.sideAlign {
  /* border: 1px solid black; */
  margin: 0.5rem;
  /* padding: 2rem 2rem; */
  text-align: center;
}
.sideAlignChild {
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;

  display: inline-block;
  vertical-align:top;
  padding: 0.5rem 0.5rem;
  /* vertical-align: middle; */
}
.sideAlignChild:hover {
  cursor: crosshair;
}

.sideAlignChildContact {
  display: inline-block;
  padding: 0.5rem 0.5rem;
  margin: 0.5rem 0.5rem;
  vertical-align: middle;
  height: 4rem;
  width: 4rem;
}
.sideAlignChildContact:hover {
  cursor: pointer;
}

.sideAlignChildShreyas {
  display: inline-block;
  padding: 0.5rem 0.5rem;
  margin: 0.5rem 0.5rem;
  vertical-align: middle;
  height: 12rem;
  width: 12rem;
}

.wrapper {
  height: 100px;
  /*This part is important for centering*/
  display: grid;
  place-items: center;
  text-align: center;
}
.wrapperDescription {
  height: 210px;
  /*This part is important for centering*/
  display: grid;
  place-items: center;
  text-align: center;
}

.typing-demo {
  width: 320px;
  animation: typing 2s steps(22), blink .5s step-end infinite alternate;
  white-space: nowrap;
  overflow: hidden;
  border-right: 3px solid rgb(0, 255, 34);
  font-family: monospace;
  font-size: 2.5em;
  color: azure;
}
.typing-demo-small {
  width: 70%;
  animation: typing2 2s steps(22);
  white-space: normal;
  overflow: hidden;
  padding-bottom: 5px;
  border-bottom: 3px solid rgb(0, 255, 34);
  font-family: monospace;
  font-size: 1em;
  color: azure;
}

@keyframes typing {
  from {
    width: 0
  }
}
@keyframes typing2 {
  from {
    width: 30%
  }
}
    
@keyframes blink {
  50% {
    border-color: transparent
  }
}

h2 {  
  font-family: monospace;
  color: rgb(92, 255, 255);
  /* font-size: 12px; */
}

p {
  font-family: monospace;
  margin: 0;
  font-size: 12px;
}
a {
  color: rgb(0, 177, 24);
}
a:hover {
  color: rgb(0, 255, 34);
}
/* p:hover {
  color: rgb(0, 255, 34);
} */

img {
  margin-top: 5px;
  margin-bottom: 5px;
  max-width: 100%;
  max-height: 100%;
}